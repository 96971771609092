import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import {Analytics} from "../../services/analytics";
import {useLanguageHook} from "../../hooks/useLanguageHook";
const environmentPath = process.env.GATSBY_MODE_ENV === 'prod'
    ? process.env.GATSBY_PROD_URL
    : process.env.GATSBY_DEV_URL;


export default function HeaderSection(props) {
    const intl = useLanguageHook();
    return (
        <Wrapper>

            <MyContainer>

                <Inner>
                    <Logo src={require('../../images/subs_love_logo.svg')}
                          onClick={() => window.location.href = window.location.origin}/>

                    <LinksPlaceholder>
                        <LinkItem onClick={() => {
                            Analytics.trackLandingMenuClick('features');
                            if(window.location.pathname === '/'){
                                let elmnt = document.getElementById("features");
                                elmnt.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/#features'
                            }

                        }}>
                            {intl.formatMessage({id: 'header.menu.features'})}

                        </LinkItem>

                        <LastLinkItem href={'mailto:hello@subtitles.love'} onClick={() => {
                            Analytics.trackLandingMenuClick('help');
                        }}>
                            {intl.formatMessage({id: 'header.menu.help'})}
                        </LastLinkItem>

                        <LastLinkItem href={intl.locale == 'ru' ? '/ru/blog' : '/blog'} onClick={() => {

                        }}>
                            {intl.formatMessage({id: 'header.menu.blog'})}
                        </LastLinkItem>

                        <LastLinkItem className={'asd'} href={'https://my.subtitles.love?login'} onClick={() => {
                            window.location.href='https://my.subtitles.love?login'
                            try {
                                window.localStorage.setItem('auth_mode', 'login');
                            } catch (exc) {

                            }
                        }}>
                            {intl.formatMessage({id: 'auth.signin'})}
                        </LastLinkItem>


                        <StartButton href={'https://my.subtitles.love/?signup'} onClick={() => {
                            window.location.href='https://my.subtitles.love?signup'

                            Analytics.send('lead.account.start', 'lead');
                            try {
                                window.localStorage.setItem('auth_mode', 'signup');
                            } catch (exc) {

                            }
                        }}>
                            {intl.formatMessage({id: 'auth.signup'})}
                        </StartButton>
                    </LinksPlaceholder>

                </Inner>

            </MyContainer>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: 17px;
    //margin-bottom: 90px;
    margin-bottom: 65px;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Logo = styled.img`
    cursor: pointer;
    height: 105px;
    width: 220px;
    @media(max-width: 720px){
      width: 100px;
      height: 60px;
      margin-right: 5px;
    }
    @media(max-width: 520px){
        width: 90px;
      height: 40px;
      margin-right: 3px;
    }
`;

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`;

const LinkItem = styled.a`
    margin-right: 40px;
    color: white;
    display: block;
  
    font-size: 17px;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
    @media(max-width: 720px){
      margin-right: 20px;
      font-size: 14px;
    }
  
  text-decoration: none;
  
  :hover {
    text-decoration: underline;
  }
`;

const LastLinkItem = styled(LinkItem)`
  
    :
    @media(max-width: 720px){
      display: none;
    }
`;

const StartButton = styled.div`
    background: #EB5E4F;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: white;
    height: 46px;
    font-weight: bold;
    cursor: pointer;
    min-width: 110px;
    :hover{
      opacity: 0.8;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
    @media(max-width: 520px){
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
    }
`;
