import React from 'react';

import styled from 'styled-components'

export default function MyContainer(props) {


    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
}

const contWidth = 1100;

const Wrapper = styled.div`
    width: ${contWidth}px;
    box-sizing: border-box;
    margin: 0 auto;
    @media(max-width: ${contWidth}px){
      width: 100%;
      padding: 5px;
    }
`;
