import React from 'react';
import styled from 'styled-components';
import MyContainer from "../containers/MyContainer";
import {useLanguageHook} from "../../hooks/useLanguageHook";
const environmentPath = process.env.GATSBY_MODE_ENV === 'prod'
    ? process.env.GATSBY_PROD_URL
    : process.env.GATSBY_DEV_URL;


const isRu = () => {
    try {
        return (window.location.href.indexOf('ru') > -1);
    } catch (exc) {
        return false;
    }
}

export default function FooterSection(props) {
    const intl = useLanguageHook();

    return (
        <Wrapper>

            <MyContainer>

                <Inner>

                    <Left>
                        <Logo src={require('../../images/subs_love_logo.svg')}
                              onClick={() => window.location.href = window.location.origin}/>

                        <LinkItem onClick={() => {

                            if(window.location.pathname === '/'){
                                let elmnt = document.getElementById("features");
                                elmnt.scrollIntoView({behavior: 'smooth'});
                            } else {
                                window.location.href = '/#features'
                            }

                        }}>
                            {intl.formatMessage({id: 'section.footer.menu.features'})}

                        </LinkItem>

                        <LastLinkItem onClick={() => {
                            window.location.href = 'mailto:hello@subtitles.love'
                        }}>
                            {intl.formatMessage({id: 'section.footer.menu.help'})}

                        </LastLinkItem>
                    </Left>


                    <LinksPlaceholder>


                        {[
                             {
                                url: 'https://www.facebook.com/SubtitlesL/',
                                 image: require('../../images/fb_im.svg')
                             },
                             {
                                url: 'https://www.youtube.com/channel/UC9CfQx1MMVykr6MhDPeZCIw',
                                 image: require('../../images/youtube.svg')
                             },
                             {
                                 url: 'https://twitter.com/subtitleslove',
                                 image: require('../../images/tw_im.svg')
                             },
                            {
                                url: 'https://www.instagram.com/subtitleslove/',
                                image: require('../../images/inst_im.svg')
                            },
                            {
                                url: 'https://discord.gg/FB56CnfxQa',
                                image: require('../../images/discord.png')
                            },
                        ].map((a, j) => {
                            return (
                                <SocialLink key={j} href={a.url} target={'_blank'}>
                                    <SocialIcon src={a.image}/>
                                </SocialLink>
                            )
                        })}

                    </LinksPlaceholder>

                </Inner>

                <BottomInner>
                    <LangPlaceholder>
                        <LangItem href={`${environmentPath}`} active={intl.locale === 'en'} href={'/'}>
                        EN
                        </LangItem>
                        {' '}
                        <LangItem active={intl.locale === 'ru'} href={`${environmentPath}/ru/`}>
                        RU
                        </LangItem>

                        <MailLink href={'mailto:hello@subtitles.love'} target={'_blank'}>
                            {'hello@subtitles.love'}
                        </MailLink>

                    </LangPlaceholder>

                    <DocsPlaceholder>
                        <DocItem href={'https://subtitles.love/blogs/wpautoterms/privacy-policy/'} target={'_blank'}>
                            {intl.formatMessage({id: 'section.footer.policy'})}
                        </DocItem>
                        <DocItem href={'https://subtitles.love/blogs/wpautoterms/terms-and-conditions/'} target={'_blank'}>
                            {intl.formatMessage({id: 'section.footer.term'})}
                        </DocItem>
                        {/*<DocItem href={'#'}>*/}
                        {/*Terms and conditionals*/}
                        {/*</DocItem>*/}
                    </DocsPlaceholder>
                </BottomInner>

            </MyContainer>

        </Wrapper>
    );
}

const Left = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Wrapper = styled.div`
    padding-top: 17px;
    padding-bottom: 37px;
    margin-top: 100px;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const BottomInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.070613);
    padding-top: 30px;
    margin-top: 30px;
    color: white;
`;

const LangPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LangItem = styled.a`
    cursor:pointer;
    display: block;
    text-decoration: none;
    color: ${props => (props.active === true ? '#EA523D' : 'white')};
    font-weight: ${props => (props.active === true ? 'bold' : 'normal')};
    margin-right: 15px;
    border-right: 1px solid lightgrey;
    padding-right: 15px;
    font-size: 17px;
    line-height: 21px;
    transition: .2s;
  
    :hover {
      color: #EA523D;
      opacity: .7;
    }
  
  
    :last-of-type{
      border-right: none;
    }
    @media(max-width: 720px){
      font-size: 14px;
      padding-right: 6px;
      margin-right: 6px;
    }
`;

const MailLink = styled.a`
    color: white;
    text-decoration: none;
    cursor: pointer;
    margin-right: 15px;
    font-size: 17px;
    line-height: 21px;
`;

const DocsPlaceholder = styled.div`
    
`;

const DocItem = styled.a`
    color: rgba(255, 255, 255, 0.5);
    margin-left: 20px;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    @media(max-width: 720px){
      font-size: 11px;
    }
`;

const Logo = styled.img`
    height: 80px;
    width: 120px;
    cursor: pointer;
    margin-right: 50px;
    @media(max-width: 720px){
      width: 80px;
      height: 60px;
      margin-right: 10px;
    }
    @media(max-width: 520px){
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
`;

const LinksPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const LinkItem = styled.div`
    margin-right: 40px;
    color: white;
    font-size: 17px;
    cursor: pointer;
    :hover{
      text-decoration: underline;
    }
    @media(max-width: 720px){
      margin-right: 15px;
      font-size: 14px;
    }
`;

const LastLinkItem = styled(LinkItem)`
    @media(max-width: 720px){
      display: none;
    }
`;

const SocialIcon = styled.img`
    height: 30px;
    @media(max-width: 920px){
      height: 24px;
    }
`;

const SocialLink = styled.a`
    margin-left: 80px;  
    @media(max-width: 920px){
      margin-left: 30px;
    }
    @media(max-width: 720px){
      margin-left: 15px;
    }
`;

