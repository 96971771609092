const gtag = typeof window !== `undefined` ? window.gtag : null

//    window.gtag('event','menu.click', {'event_category' : 'menu.download'});

export class Analytics {

    static send(action = null, category = null, callback = () => {
    }, ...rest) {
        if (gtag != null) {
            gtag('event', action, {
                'event_category': category,
                'event_callback': () => callback(),
                ...rest
            });
        }
    }

    static sendDimensionEvent(action = null, dimensionName, dimensionValue) {
        try {
            if (gtag != null) {
                gtag('event', action, {
                    [dimensionName]: dimensionValue
                });
            }
        } catch (exc) {

        }
    }

    static trackLandingMenuClick(menuName) {
        try {
            Analytics.sendDimensionEvent('landing_menu_click', 'landing_menu_hit', menuName);
        } catch (exc) {

        }
    }

    static trackTariffClick(tariffName) {
        try {
            Analytics.sendDimensionEvent('landing_tariff_click', 'landing_tariff_hit', tariffName);
        } catch (exc) {

        }
    }

}
