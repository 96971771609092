import languages from './../languages';


export function useLanguageHook () {
    const lang = process.env.GATSBY_LANG_ENV;
    return {
        locale: lang,
        formatMessage: ({id}) => languages[lang][id]
    };
}
